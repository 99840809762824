@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url('https://fonts.googleapis.com/css?family=Montserrat');
.hero {
  text-align: center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 445px;
}
.hero::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0.7;
}
.mv-single-hero {
  background-repeat: no-repeat;
  height: 598px;
}
.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.hero-ontop {
  z-index: 1;
}
a,
a:active,
a:visited {
  cursor: pointer;
  transition: all 300ms ease-in-out;
  text-decoration: none;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}
.btn {
  font-size: 13px;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  width: auto;
  padding: 12px 24px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #fff;
  border-width: 2px;
  border-radius: 30px;
}
.btn i,
.btn:hover i {
  position: relative;
  z-index: 1;
  display: inline-flex;
  vertical-align: middle;
  color: #fff;
}
.btn-default {
  border: none;
  background-image: linear-gradient(to right, #fbbd61, #ec7532);
}
.btn-default span,
.btn-default span:hover,
.btn-ghost span:hover {
  position: relative;
  z-index: 1;
  color: #fff;
}
.btn-default:hover::before {
  width: 140%;
}
.btn-default::before,
.btn-ghost::before {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -15px;
  width: 0;
  height: 100%;
  content: '';
  transition: .4s;
  transform: skewX(30deg);
  background: #ec7532;
}
.btn-primary {
  border: none;
  background: #ec7532;
}
.btn-ghost {
  transition: all 300ms ease-in-out;
  color: #ec7532;
  border-color: #ec7532;
  background: transparent;
}
.btn-ghost:hover span {
  color: #fff;
}
.btn-ghost:hover::before {
  z-index: -1;
  width: 140%;
}
.btn-ghost:hover {
  color: #fff;
}
button.btn-default,
button.btn-primary {
  transition: all 300ms ease-in-out;
}
button.btn-default::before,
button.btn-primary::before {
  display: none;
}
button.btn-default:hover,
button.btn-primary:hover {
  color: #fff;
  background: #fbbd61;
}
a.arrow-button {
  font-size: 12px;
  position: relative;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #ec7532;
}
a.arrow-button::after {
  font-family: 'Material Icons';
  font-weight: bold;
  position: absolute;
  top: -1px;
  content: 'chevron_right';
  text-transform: none;
  -webkit-font-feature-settings: 'chevron_right';
}
a.arrow-button:hover {
  color: #101010;
}
.star-rating {
  display: inline-block;
}
.star-rating i {
  font-size: 18px;
  display: inline-block;
  width: 18px;
  color: #fbbd61;
}
i.material-icons {
  display: inline-flex;
  vertical-align: middle;
}
#content_hero .scroll {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  margin-left: -45px;
  animation: ScrollDown 1.5s infinite;
}
#content_hero::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  content: '';
  background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
#content_hero .container .blurb {
  position: relative;
  width: 100%;
  margin-top: 50px;
  animation: Blurb .5s .5s forwards;
  text-align: left;
}
#content_hero .container .blurb h1 {
  font-size: 52px;
  font-weight: 300;
  padding: 0;
  animation: Heading .5s .5s forwards;
  letter-spacing: 3px;
  opacity: 0;
  color: #fff;
  border: none;
}
#content_hero .container .blurb p {
  font-size: 18px;
  color: #fff;
}
#content_hero .container .blurb .certificate {
  font-size: 13px;
  font-weight: bold;
  line-height: 43px;
  display: inline-block;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  transform: translateY(2px);
  text-align: center;
  color: #fff;
  border: solid 2px #fff;
  border-radius: 50%;
}
#content_hero {
  position: relative;
  margin-top: 5%;
  transition: all 300ms ease-in-out;
  /* background-position: center; */
  background-size: cover;
}
#content_hero::before {
  top: auto;
  height: 70%;
}
#content_hero .container .blurb {
  margin-top: 200px;
  margin-bottom: 100px;
}
#content_hero .container .blurb h1 {
  animation: none;
  opacity: 1;
}
#content_hero .buttons .btn {
  margin-right: 15px;
}
span.title {
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fbbd61;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: rgb(0, 0, 0);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo {
  width: 150px;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.animated-text {
  position: relative;
  z-index: 2;
  /* Ensure text is above overlay */
  color: white;
  /* Example text styling */
  text-align: center;
  /* Example text alignment */
  animation: fadeInUp 1s ease-in-out;
}
.play-now-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.play-now-btn:hover {
  background-color: #0056b3;
}
.slider-container {
  max-width: 100%;
  overflow: hidden;
  padding: 8px;
}
.slick-list {
  overflow: hidden;
}
.slick-track {
  display: flex;
  align-items: center;
}
.video-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}
.play-button {
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
}
.seek-bar {
  width: 300px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.card-slider {
  margin-top: 10rem;
  padding: 2px;
  margin: 0px;
}
.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.slick-dots {
  bottom: -30px;
}
.card-wrapper {
  padding: 5px;
}
.card {
  border: none;
  /* border-radius: 5px; */
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card-img-top {
  width: 100%;
  height: auto;
}
.card-body {
  padding: 15px;
}
.card-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}
.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
}
.home-heading {
  color: #f1f1f1;
  padding: 2px;
  margin: 0px;
  margin-left: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}
.mt-1 {
  margin-top: 1% !important;
}
.mt-2 {
  margin-top: 2% !important;
}
.mt-3 {
  margin-top: 3% !important;
}
.mt-4 {
  margin-top: 4% !important;
}
.mt-5 {
  margin-top: 5% !important;
}
.mt-6 {
  margin-top: 6% !important;
}
.mt-7 {
  margin-top: 7% !important;
}
.mt-8 {
  margin-top: 8% !important;
}
.mt-9 {
  margin-top: 9% !important;
}
.mt-10 {
  margin-top: 10% !important;
}
.mt-20 {
  margin-top: 20% !important;
}
.carousel-img {
  
  /* height: 600px; */
  /* Fixed height */
  /* object-fit: fill; */
  /* Ensures the image covers the container */
}
.slick-slider-img {
  height: 250px;
  /* Fixed height */
  object-fit: cover;
  /* Ensures the image covers the container */
}

.carousel-indicators {
  display: none;
}
.carousel-number {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgb(255 185 185 / 1%) !important;
  /* top: 0; */
  /* left: 0; */

  top: 70px;
    left: -50px;
  /* Black opacity */
  /* transform: translate(-50%, -50%); */
  color: #ffffffc2;
  font-size: 135px;
  font-weight: bold;
  text-align: left;
  text-shadow: 2px 2px 4px #0000008f;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 22%);
  /* Black opacity overlay */
  z-index: 1;
}
.carousel-video {
  width: 100%;
  height: auto;
}
.play-btn {
  position: absolute;
  top: 80%;
  left: 12%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  text-shadow: 2px 2px 4px #0000008f;
  z-index: 2;
  color: white;
  /* Example text styling */
  text-align: center;
  /* Example text alignment */
}


button.forward-button {
  font-size: 3rem;
  margin-left: 22%;
  margin-top: 0 !important;
  position: relative;
  background: none;
  background: none;
  color: white;
  border: none;
}


button.backward-button {
  font-size: 3rem;
  margin-left: -27%;
  margin-top: 0px !important;
  position: absolute;
  background: none;
  color: white;
  border: none;
}
.seek-bar {
  height: 2px !important;
  z-index: 2;
  color: white;
  text-align: center;
  width: 93%;
  height: 4px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  position: absolute;
  top: 91%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000f0;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  text-shadow: 2px 2px 4px #0000008f;
}
.hotmovie {
  width: 100%;
  border-radius: 10px;
}
.fade-in-hello {
  animation: fade 0.9s ease-in;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
}
.watch-buttons {
  text-align: right;
}
.react-player {
  height: 650px !important;
}
.ml-1 {
  margin-left: 1%;
}
.movie-title {
  color: white;
  font-weight: 700;
}
.movie-description {
  margin-top: 1%;
  color: white;
}
/* .login_phone_number_input.form-control {
  width: 30%;
  margin-left: 41%;
} */
/* Media queries */

.top-trending-container{
  margin-top: -18%;
}


@media (min-width: 1200px) {
  img.mobile-frame-inside {
    position: sticky !important;
    text-align: center !important;
    margin-left: 67px !important;
    margin-top: -143% !important;
    width: 67% !important;
}

.mobile-number-form.col-md-12.col-sm-12 {
  position: relative;
  margin-top: -100%;
  /* width: 90%; */
  /* text-align: center; */
  /* margin-left: 4%; */
  width: 88%;
    margin-left: 5%;
}

.login-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 81%;
  width: 100%;
  background: rgb(20 13 13 / 98%);
  pointer-events: none;
}

.login-bottom-text {
  position: relative;
  text-align: center;
  margin-top: -12%;
}

img.mobile-frame {
  position: relative;
  text-align: center;
  margin-left: 17%;

}

}

@media (max-width: 1200px) {
  img.mobile-frame-inside {
    position: sticky !important;
    text-align: center !important;
    margin-left: 5px !important;
    margin-top: -143% !important;
    width: 67% !important;
}
  .login_phone_number_input.form-control {
    width: 40%;
    margin-left: 40%;
  }


  .movie-list-section {
    margin-top: 9%;
  }
  .react-player {
    height: 650px !important;
  }
  .carousel-number {
    font-size: 130px;
    top: 32%;
    left: -34%;
  }
  .play-btn {
    top: 82%;
    left: 10%;
    font-size: 23px;
  }
  .slick-slider-img {
    height: 0%;
    width: 100%;
  }
  .carousel-img {
    /* height: 450px; */
    /* Fixed height */
  }
  .home-heading {
    font-size: 1rem;
    font-weight: 600;
  }
  .play-button {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  .seek-bar {
    width: 170px;
  }

  .profile-page-container {
    margin-top: 8%;
}
}
@media (max-width: 992px) {

  img.mobile-frame-inside {
    position: sticky;
    text-align: center;
    margin-left: 5px;
    margin-top: -143%;
    width: 67%;
}
  .profile-page-container {
    margin-top: 8%;
}
  .otp_input_field {
    margin-left: 750% !important;
  }
  .login_phone_number_input.form-control {
    width: 62%;
    margin-left: 35%;
  }
  .movie-list-section {
    margin-top: 12%;
  }
  .play-button {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .play-btn {
    top: 82%;
    left: 10%;
    font-size: 23px;
  }
  .seek-bar {
    width: 140px;
  }
  .carousel-number {
    font-size: 120px;
    top: 22%;
    left: -32%;
  }
  .slick-slider-img {
    height: 170px;
    /* width: 100% !important; */
  }
  .carousel-img {
    /* height: 550px; */
    /* Fixed height */
  }
}
@media (max-width: 768px) { 

  img.mobile-frame-inside {
    position: sticky;
    text-align: center;
    margin-left: 5px;
    margin-top: -143%;
    width: 67%;
}

  .profile-page-container {
    margin-top: 8%;
}
  /* movie detail section */

  div#content_hero {
    background-size: contain !important;
    background-repeat: no-repeat;
    height: 60vh !important;
    /* height: 44vh !important; */
        /* margin-bottom: 10%; */
}

.movie-detail-tile {
  font-size: 30px !important;
  font-weight: 700 !important;
}

.movie-detail-description {
  font-size: 14px !important;
}

.buttons.movie-detail-btn {
  font-size: 10px !important;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  width: auto;
  padding: 0px 0px !important;
  letter-spacing: 1px !important;
  text-transform: uppercase;
  color: #fff;
  border-width: 2px;
  border-radius: 0px;
}

/*  end of movie detail section */



  .otp_input_field {
    margin-left: 0% !important;
  }
  .login_phone_number_input.form-control {
    width: 89%;
    margin-left: 6%;
  }
  .tv-show-list {
    margin-top: 12%;
  }
  .movie-list-section {
    margin-top: 13%;
  }
  .react-player {
    height: 500px !important;
  }
  .play-btn {
    top: 80%;
    left: 13%;
    font-size: 23px;
  }
  .play-button {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
  .seek-bar {
    width: 100px;
  }
  .carousel-number {
    font-size: 80px;
    top: 30%;
    left: -37%;
  }
  .slick-slider-img {
    height: 160px;
  }
  .carousel-img {
    margin-top: 13%;
    /* height: 350px; */
    /* Fixed height */
  }


  .download-btn {
    margin-top: 14%;
    text-align: left;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 1px;
    margin-left: -16%;
    /* background-color: white !important; */
    /* color: black; */
}
}

@media (max-width: 576px) {


  img.mobile-frame-inside {
    position: sticky !important;
    text-align: center !important;
    margin-left: 15% !important;
    margin-top: -140% !important;
    width: 67% !important;
  }

  img.mobile-frame {
    position: relative;
    text-align: center;
    margin-left: 17%;
  
    
  }


  .mobile-number-form.col-md-12.col-sm-12 {
    position: relative;
    margin-top: -111%;
    /* width: 90%; */
    /* text-align: center; */
    /* margin-left: 4%; */
    width: 88%;
      margin-left: 5%;
  }

  .profile-page-container {
    margin-top: 8%;
  }
  .download-btn {
    margin-top: 14%;
    text-align: left;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 1px;
    margin-left: -16%;
    /* background-color: white !important; */
    /* color: black; */
}

  /* movie detail section */

    .movie-detail-title {
      margin-top: -11% !important;
      text-align: justify;
    }

    div#content_hero {
      background-size: contain !important;
      background-repeat: no-repeat;
      height: 37vh !important;
      margin-bottom: 0%;
  }
  
  .movie-detail-tile {
    font-size: 30px !important;
    font-weight: 700 !important;
  }
  
  .movie-detail-description {
    display: none !important;
    font-size: 14px !important;
  }
  
  .buttons.movie-detail-btn {
    font-size: 10px !important;
    font-weight: normal;
    position: relative;
    overflow: hidden;
    width: auto;
    padding: 0px 0px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase;
    color: #fff;
    border-width: 2px;
    /* border-radius: 30px; */
  }
  
  /*  end of movie detail section */


  .otp_input_field {
    margin-left: 400%;
  }
  .tv-show-list {
    margin-top: 19%;
  }
  .movie-list-section {
    margin-top: 18%;
  }
  .react-player {
    /* height: 340px !important; */
    margin-top: 6%;
    height: 100% !important;
  }
  .play-button {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .seek-bar {
    width: 86px;
  }
  .carousel-number {
   /*  font-size: 60px;
    top: 0%;
    left: 0%; */

    font-size: 70px;
        top: 30%;
        left: -37%;
  }
  .slick-slider-img {
    height: 160px;
  }
  .top-trending-container{
    margin-top: 0%;
  }
  .carousel-img {
    margin-top: 13%;
    /* height: 350px; */
    /* Fixed height */
  }
  .profile-page-container{
    margin-top: 20%;
  }


/*   .carousel-item img {
    height: 270px !important;
    object-fit: fill;
    margin-top: 12%;
}
  .carousel-img{
    height: 270px;
    object-fit: fill;
    margin-top: 12%;
} */

.plan-lists {
  margin-top: -28%;
}

/* img.mobile-frame-inside {
  position: sticky;
  margin-left: 118px;
  margin-top: -107%;
} */
  .play-btn {
    top: 79%;
    left: 15%;
    font-size: 20px;
  }
  .rupee-symbol {
    font-size: 1.5rem;
    margin-top: -5%;
  }
  .login_phone_number_input.form-control {
    width: 89%;
    font-size: 1.8rem !important;
    margin-left: 6%;
  }
}



.plan-price {
  font-size: 2.5rem;
  margin-top: -43% !important;
  margin-left: 20% !important;
  font-weight: 900;
}
/* max with 576 end */
.carousel-item {
  position: relative;
  /* Ensures the number is positioned relative to the image */
}
.bg-dark {
  --bs-bg-opacity: 0.5;
  background-color: rgb(0 0 0) !important;
}
.navbar-transparent {
  background-color: transparent;
  transition: background-color 0.5s ease;
  padding: 3px;
}
.navbar-scrolled {
  background-color:rgb(0 0 0 / 73%) !important;
  transition: background-color 0.5s ease;
  padding: 3px;
}
.none {
  display: none !important;
}
/* .carousel-item img {
  height: 600px;
  object-fit: cover;
} */
.carousel-caption {
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 20px;
  border-radius: 10px;
}
a,
u {
  text-decoration: none;
}
.nav-link {
  text-decoration: none !important;
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: white;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.nav-link:focus,
.nav-link:hover {
  color: var(--bs-nav-link-hover-color);
  color: white;
}
.active-link {
  color: white;
  border-bottom: 2px solid #ff0054;
}
.video-player-wrapper {
  position: relative;
  /* padding-top: 56.25%; */
  height: 40%;
  overflow: hidden;
}
.video-player-wrapper iframe {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
}
.watch_now_play_btn {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGwxMiA4LTEyIDhWMHoiLz48L3N2Zz4=);
  background-color: rgba(0, 0, 0, .3);
  background-position: 30px center;
  background-repeat: no-repeat;
  background-size: 40% 50%;
  border: 3px solid #fff;
  border-radius: 50%;
  height: 86px;
  left: 50%;
  margin: -43px 0 0 -43px;
  position: absolute;
  /* top: 50%; */
  width: 86px;
  cursor: pointer;
  z-index: 5;
  display: block;
}

a.upgrade-plan {
  /* border: 1px solid gray; */
  padding: 10px;
  color: white;
  background: #ec7532;
  border-radius: 10px;
  font-size: 15px;
}



/* .watch_now_play_btn:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGwxMiA4LTEyIDhWMHoiLz48L3N2Zz4=);
  background-color: rgba(151, 151, 13, 0.626);
  background-position: 30px center;
  background-repeat: no-repeat;
  background-size: 40% 50%;
  border: 3px solid #89940889;
  border-radius: 50%;
  height: 86px;
  left: 50%;
  margin: -43px 0 0 -43px;
  position: absolute;
  top: 50%;
  width: 86px;
  cursor: pointer;
  z-index: 5;
  display: block;
} */

.login-card {
  background-image: url('https://tamashatv.com/site_assets/images/login-signup-bg-img.jpg');
  /* background: #ffffff24; */
}
.bg-black {
  background: black;
}
.login_phone_number_input.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  border-radius: 0;
  color: rgb(255 255 255);
}
.login_phone_number_input::placeholder {
  color: rgb(255, 255, 255);
  /* Change this to the desired color */
  opacity: 1;
  /* Optional: Ensure full opacity for placeholder text */
}
.otp_input_field {
  background: transparent;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  /* margin-left: 599%; */
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: #fff;
  border-color: #000000;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
  color: black;
}
.otp_verify_btn.btn.btn-primary {
  width: 100%;
  text-align: center;
  /* margin-left: 12%; */
}
.mobile-otp-btn.btn.btn-primary {
  width: 100%;
  text-align: center;
}
.plan_card {
  /* background: #2c2727c4; */
  border-color: rgb(165, 137, 80);
  background: radial-gradient(86.28% 289.13% at 50% 50%, rgb(236 26 26 / 56%) 0%, rgb(251 99 154 / 37%) 50%);
  overflow: initial;
  color: white;
  cursor: pointer;
}
.plan_title {
  text-align: justify;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.25;
}
.plan-benefits {
  text-align: justify;
  font-size: .9375rem;
  line-height: 1.25;
}
/* .rupee-symbol {
  position: absolute;
} */
.rupee-symbol {
  font-size: 1.5rem;
}

del.discount-price {
  font-size: 1.5rem;
}
.plan-price {
  font-size: 2.5rem;
  margin-top: -54%;
  margin-left: 21%;
  font-weight: 900;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: #0d6efd00;
  border: 1px solid rgb(192, 162, 99);
  padding: 7px 10px 7px 10px;
  border-radius: 3px;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.25;
  text-align: right;
  opacity: var(--bs-btn-disabled-opacity);
}
.btn-primary {
  background-color: #0d6efd00;
  border: 1px solid rgb(192, 162, 99);
  padding: 7px 10px 7px 10px;
  border-radius: 3px;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.25;
  text-align: right;
  opacity: var(--bs-btn-disabled-opacity);
}
/* button.btn-default:hover,
button.btn-primary:hover {
  color: #fffbfb;
  background: #ffc165;
} */

button.btn-default:hover, button.btn-primary:hover {
  color: #fffbfb;
  background: #ec3316;
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  /* border-color: var(--bs-btn-hover-border-color); */
}
.active_plan {
  border: 4px solid rgba(165, 137, 80, 1);
}
.plan-days-div {
  text-align: left;
  margin-top: 2%;
}
.plan-days {
  border: 1px solid rgb(192, 162, 99);
  padding: 7px 10px 7px 10px;
  border-radius: 3px;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.25;
  text-align: right;
}
/* slick slide arrow */
.custom-next-arrow,
.custom-prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff00;
  border: none;
  padding: 2px;
  cursor: pointer;
  z-index: 1;
  font-size: 35px;
  color: #ffffff73;
}
.custom-next-arrow {
  right: 10px;
}
.custom-prev-arrow {
  left: 10px;
}
/* end of slick slider  */
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.access-mode {
  margin-top: -124%;
  margin-left: 70%;
  /*   font-size: 15px;
  border: 2px solid #ffa50bf2;
  padding: 5px;
  border-radius: 50%;
  color: #ffa50bf2; */
  background: #ffa50bf2 !important;
  /* background: linear-gradient(60deg, #ff8508, #fd0575) !important; */
  border-radius: 40px;
  padding: 5px 6px;
  font-size: 12px;
  position: absolute;
  /* z-index: 2; */
  /* right: 8px; */
  /* top: 8px; */
  /* width: 28px; */
  /* height: 28px; */
}
.user-profile-image {
  width: 35px;
}


.movie-detail-title {
  margin-top: 5%;
  text-align: justify;
}


  .btn-primary {
    background-color: #ec7532;
    border: 1px solid #ec7532;
    opacity: 1;
}



.btn:hover {
  color: #fff;
  background-color: #ec7532;
  border: none;
  /* border-color: var(--bs-btn-hover-border-color); */
}


/* card animation  */
.card .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.card .image img {
  width: 100%;
  transition: .5s;
}


.card:hover .image img {
  opacity: .5;
  transform: translateX(0%);/*100%*/
}

.card .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;/*100%*/
  height: 100%;
  background: #00000097;
  transition: .5s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}
.card:hover .details {
  transform: perspective(2000px) rotateY(0deg);
}
.card .details .center {
  padding: 20px;
  text-align: center;
  /* background: #ffffff51; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.card .details .center h1 {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-weight: 700;
  line-height: 20px;
  font-size: 20px;
  text-transform: uppercase;
}
.card .details .center h1 span {
  font-size: 14px;
  color: #fffcfc;
}
.card .details .center p {
  margin: 10px 0;
  padding: 0;
  color: #f7f7f7;
}
.card .details .center ul {
  margin: 10px auto 0;
  padding: 0;
  display: table;
}
.card .details .center ul li {
  list-style: none;
  margin: 0 5px;
  float: left;
}
.card .details .center ul li a {
  display: block;
  background: #262626;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transform: .5s;
}
.card .details .center ul li a:hover {
  background: #ec7532;
}
/* end card animation */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

img.login-logo {
  width: 116px;
  margin-top: 10px;
  margin-left: 20px;
}


.login-bg {
  width: 100%;
  height: 100%;
  
  background-image: url('../public/images/login_components/background_image.png');
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 7%;
    right: 0;
    bottom: 0;
    left: 0;
    
    background-image: linear-gradient(to bottom right, #000000, #000000);
    opacity: .8;
}
}



.login-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 458px;
  width: 100%;
  background: rgb(20 13 13 / 98%);
  pointer-events: none;
}

img.mobile-frame-more-like {
/*   position: relative;
  margin-top: 0%;
  margin-left: -79%;  */

  position: fixed;
  margin-top: 49%;
  margin-left: -83%;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 7%;
    right: 0;
    bottom: 0;
    left: 0;
    
    background-image: linear-gradient(to bottom right, #000000, #000000);
    opacity: .8;
}
}



.get-otp-btn {
  width: 100%;
  margin-top: 8%;
  text-align: center;
  font-size: 20px;
  text-transform: none;
  letter-spacing: normal;
}


span.login-heading-2 {
  color: white;
  background-image: linear-gradient(#44ECF7, #57C2FF);
  color: transparent;
  background-clip: text;
  font-size: 57.38px;
  font-weight: 700;
  margin-top: -4%;
}

span.login-heading-3 {
  color: #FFFFFF;
  font-size: 23.63px;
  margin-top: -3%;
}
span.login-heading-1 {
  color: #FFFFFF;
  font-size: 23.63px;
  font-weight: 400;
}

.login-bottom-text {
  position: relative;
  text-align: center;
  margin-top: -28%;
}

.plan-top-image {
  width: 100%;
  height: 30%;
  margin-top: 10%;
  object-fit: cover;
}

span.plan-top-heading {
  color: white;

  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}

.plan-benefits.card {
  background: linear-gradient(286deg, #FF7E06, #D86A03);
  color: white;
  font-size: 14px;
  font-weight: 400;
}


/* plan section */
/* #FF7E06 */

.planmain-div {
 
  padding: 10px;
  border-radius: 10px;
  color: rgb(232, 230, 230);
}

.plan-active
{
  border: 1px solid #ff810a;
}
.not-active-plan
{
  border: 1px solid #8C9A9E;
}

.percentage-off-div {
  text-align: right;
}
.percentage-off-txt {
  padding: 13px;
  margin-right: -10px;
  /* background-color: #8C9A9E; */
  border-top-left-radius: 42px;
  border-bottom-left-radius: 11px;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.active-plan-off{
  background-color: #ff810a;
 
}
.not-active-plan-off{
  background-color: #8C9A9E; 
}


span.recpmmended-plan {
  font-size: 9px;
  margin-top: -21px;
  margin-left: 16px;
  padding: 1px;
  border: 1px solid #ff810a;
  width: 19%;
  height: 0%;
  text-align: center;
  border-radius: 5px;
  color: #ff8717;
  padding-left: 5px;
  padding-right: 5px;
  background-color: black;
}



.payment-amnt-rupee-symb.col-md-1.col-sm-1.col-1 {
  font-size: 10px;
  text-align: right;
}


span.payment-amnt {
  margin-left: -14px;
  font-weight: 700;
  font-size: 27px;
  text-align: left;
}
.payment-amnt-div{
  margin-top: -10px;
  margin-bottom: -6px;
}

.payment-dis-amnt-rupee-symb {
  font-size: 10px;
  margin-top: 5px;
}

.discount-amnt {
  text-align: left;
  margin-left: -16px;
  font-weight: 700;
}

.plan-list-section{
  margin-top: -20%;
}

.proceed-btn {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  background: #f67904;
  border: none;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 14px;
}

.desktop-plan-page-main.row {
  background-image: url('https://tamashatv.com/site_assets/images/login-signup-bg-img.jpg');
  position: absolute;
  width: 100%;
  height: 100%;
  top: 7%;
  /* left: 50%; */
  /* font-size: 20px; */
  color: white;
  /* transform: translate(-50%,-50%); */
  /* -ms-transform: translate(-50%,-50%); */
}

/* end plan section */

/* 
p.card-text {
  text-align: justify;
  margin-left: 25%;
}
 */


 .profile-card{
    border: 1px solid gray;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #232323;
    color: white;
    padding: 20px;

 }

 .watchlist_share_btn{
  color: white;
  font-size: 20px;
}



/* video controls */

.custom-player {
  position: relative;
  width: 100%;
  height: 100%;
}

.controls {
  position: relative;
  /* position: relative; */
  bottom: 350px;
  margin-left: 45%;
  /* left: 10px; */
  display: flex;
  gap: 10px;
  /* background: rgb(0 0 0 / 18%); */
  padding: 10px;
  /* border-radius: 5px; */
  width: 100%;
}
.play-button {
  /* background-color: #ff4081; */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 3rem;
  padding: 0px 0px;
  margin-top: 7px;
  background: none;
}

/* .play-button,
.backward-button,
.forward-button {
  background-color: #ff4081;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
} */

.volume-bar {
  transform: rotate(270deg);
  height: 2px;
  margin-top: 20%;
  position: absolute;
  margin-left: -47%;

}

.play-button:hover,
.backward-button:hover,
.forward-button:hover {
  /* color: #f67904; */
  color: #ec3318;
  /* background-color: #ff1f5b; */
}
.seek-bar {
  width: calc(100% - 20px);
  margin: -10px 0px;
}

.pip-button,.speed-control {
  background: #c32b0c0f;
  color: white;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 0%;
  /* padding: 0px; */
}


.app-download {
  background: -webkit-linear-gradient(90deg, #000000,#ff0000);
  background: linear-gradient(90deg, #000000,#ff0000);
  /* background-color: rgb(159, 226, 191); */
  border-top: 2px solid linear-gradient(90deg, #ff0000,#000000);
  position: fixed;
  width: 100%;
  bottom: 0;
  color: rgb(48, 48, 48);
  font-size: 20px;
  height: 70px;
}

.download-btn{
  margin-top: 10%;
  text-align: left;
  font-size: 11px;
  text-transform: none;
  letter-spacing: 1px;
  margin-left: -10%;
  /* background-color: white !important; */
  /* color: black; */
}
.download-btn:hover{
  background-color: white !important;
  color: black !important;
}
.text-left
{
  text-align: left;
}

select.age-certificate-select.form-select {
  background: #232323;
  color: white;
}


button.btn.btn-sm.age-certi-btn {
  border: 1px solid gray;
  padding: 4px;
  border-radius: 0px;
  margin-top: 10px;
}


.text-right
{
  text-align: right;
}

.search-bar{
      background-color:#00000080;
    border: 1px solid #5953533d;
}
.search-bar:hover{
  background-color:#000;
  border: 1px solid white;
  color: white;
}

/* seekbar color */

/* @media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
 
    -webkit-appearance: none;
    background-color: #9a905d;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: pointer;
    background: #434343;
    box-shadow: -80px 0 0 80px #43e5f7;
  }

} */
/** FF*/
input[type="range"]::-moz-range-progress {
background-color: #43e5f7; 
}
input[type="range"]::-moz-range-track {  
background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
background-color: #9a905d;
}

/* end od seekabr color */

/* .mt-minus-4{
  margin-top: -4%;;
} */